import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { Firebase } from "../../firebase/config";
import PostCards from "../PostCardScrap/PostCards";
import CircleLoading from "../LoadingsPages/CircleLoading";
import "./allfilterpostees.css";
import InfiniteScroll from 'react-infinite-scroll-component';
function AllPostdetail() {
  const [posts, setPosts] = useState([]);
  const [lastKey, setLastKey] = useState("");
  const [nextloading, setNextLoading] = useState(true);

  const { type } = useParams();
  console.log(type)
  // const stateParmvalu=useLocation().state;
  // console.log("ywwe"+type);
  // console.log(stateParmvalu);
  
  useEffect(() => {
    console.log("calling")
    if(type=="all"){
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
      .collection("products")
      .orderBy("time", "desc").limit(8)
      .get()
      .then((snapshot) => {
        let filterpost = snapshot.docs.map((product) => {
          const idd=product.id;
          //lastKey = product.data().createdAt;
          return {
              id: idd,
            ...product.data(),
            
          };
        });
        const lastKey=snapshot.docs[snapshot.docs.length-1];
        setPosts(filterpost);
        setLastKey(lastKey)
     
      });
    }
    else{
      try {
        Firebase.firestore() //retreving all posts from firebase in asecnding order of date
      .collection("products").where("category","==",type)
      .orderBy("time", "desc").limit(8)
      .get()
      .then((snapshot) => {
        let filterpost = snapshot.docs.map((product) => {
          const idd=product.id;
         
          return {
              id: idd,
            ...product.data(),
            
          };
        });
        const lastKey=snapshot.docs[snapshot.docs.length-1];
        setPosts(filterpost);
        setLastKey(lastKey)
     
      });
  
      }
       catch (e) {
        console.log(e);
      }

    }
   
  }, [type]);

  /**
  * used to apply pagination on posts
  * @param {String} key
  * @return next batch of posts (+5 posts)
  * will be fired when user click on 'More Posts' button.
  */

  const fetchMorePosts = (key) => {

    if(type=="all"){
      Firebase.firestore() //retreving all posts from firebase in asecnding order of date
      .collection("products")
      .orderBy("time", "desc").startAfter(key).limit(8)
      .get()
      .then((snapshot) => {
        let filterpost = snapshot.docs.map((product) => {
          const idd=product.id;
          //lastKey = product.data().createdAt;
          return {
              id: idd,
            ...product.data(),  
          };
          
        }
        );
        const lastKey=snapshot.docs[snapshot.docs.length-1];
        setLastKey(lastKey);
        // console.log(lastKey)
        setPosts(posts.concat(filterpost));
        if(lastKey===undefined){
          setNextLoading(false)
        }
      });
      
    }
    else{
    
        try {
          
          Firebase.firestore() //retreving all posts from firebase in asecnding order of date
        .collection("products").where("category","==",type)
        .orderBy("time", "desc").startAfter(key).limit(8)
        .get()
        .then((snapshot) => {
          let filterpost = snapshot.docs.map((product) => {
            const idd=product.id;
            return {
                id: idd,
              ...product.data(),  
            }; 
          }
          );
          const lastKey=snapshot.docs[snapshot.docs.length-1];
          setLastKey(lastKey);
          console.log("last key "+lastKey)
          setPosts(posts.concat(filterpost));
          if(lastKey===undefined){
            setNextLoading(false)
          }

        });
    
        }
         catch (e) {
          console.log(e);
        }
      
    }

  };

  let allPosts = (
    // type == "all" ? 
    posts.map((product, index) => {
      return (
        <div className="cardfilter" key={index}>
          {" "}
          <PostCards product={product} index={index} />{" "}
        </div>
      );
    })
  );



  return (
    <>
      <div className="filter-posts">
        <div className="allpostbox">
          <InfiniteScroll
            dataLength={allPosts.length} //This is important field to render the next data
            next={() => fetchMorePosts(lastKey)}
            hasMore={true}
            loader={
              nextloading?
            <div  
              style={{
              display: 'flex',
              justifyContent: 'center', 
            }}
            ><CircleLoading/></div>:
            <div   style={{
              display: 'flex',
              justifyContent: 'center', 
            }}
            > End Posts</div>
          }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div className="filterpost-container">
              {allPosts}

            </div>
          </InfiniteScroll>
        </div>
      </div>

    </>
  );
}

export default AllPostdetail;



