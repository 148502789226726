import React,{useState} from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation
import scrapImage from '../../assets/recycle.png';
import { ReactComponent as ArrowIcon }  from '../../assets/arrow-up.svg';
import { motion } from 'framer-motion';
import { useAuth } from "../../Auth/AuthContext copy";
import Loogin from "../Login/Login"
import AlertVerifyEmail from '../../verify_alert';
const Picturebaner  = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const closeAlert = () => {
    setShowAlert(false);
  };
  const { currentUser, setModal, } = useAuth();
  const openLogin = () => {
    setModal({ isOpen: true, title: 'Login', content: <Loogin /> });
  };
  const handleGetStartedClick = () => {
    if (currentUser && currentUser.emailVerified) {
      navigate('/createpost'); // Redirect to the "View More" page
    } else if (currentUser && !currentUser.emailVerified) {
      setShowAlert(true);
    } else {
      openLogin(); // Show login modal if the user is not logged in
    }
  };

  // Animation Variants
  const textVariant = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const imageVariant = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.2 } },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 4,
        borderRadius: 0, // Set border radius to zero
        background: 'linear-gradient( #ffffff, #e0f2e9)', // White to light green gradient matching navbar
        boxShadow: 0,
        overflow: 'hidden',
      }}
    >
       {showAlert && <AlertVerifyEmail closeAlert={closeAlert} />}
      {/* Left Side Content with Animation */}
      <motion.div
        variants={textVariant}
        initial="hidden"
        animate="visible"
        style={{ flex: 1 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: { xs: 'center', md: 'flex-start' },
            textAlign: { xs: 'center', md: 'left' },
            padding: 2,
            color: 'black',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            gutterBottom
            sx={{
              fontSize: {
                xs: '1.5rem', // Font size for mobile devices
                sm: '2rem',   // Font size for tablets
                md: '2.5rem', // Font size for small laptops
                lg: '3rem',   // Font size for large desktops
              },
            }}
          >
            <span style={{ color: 'black' }}>Buy and Sell </span>
            <span
              style={{
                background: 'linear-gradient(to right, #56ab2f, #a8e063)', // Green gradient for "Recycled"
                WebkitBackgroundClip: 'text',
                color: 'transparent',
              }}
            >
              Recycled
            </span>{' '}
            <span style={{ color: 'black' }}>Material</span>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '0.875rem', // Font size for mobile devices
                sm: '1rem',     // Font size for tablets
                md: '1.125rem', // Font size for small laptops
                lg: '1.25rem',  // Font size for large desktops
              },
              mb: 2,
            }}
          >
            Join our community to trade recycled materials with ease.
          </Typography>
         

          <Button
  onClick={handleGetStartedClick}
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: {
      xs: '90px',  // Width for phones
      sm: '100px', // Width for tablets
      md: '120px'  // Width for laptops
    },
    height: {
      xs: '90px',  // Height for phones
      sm: '100px', // Height for tablets
      md: '120px'  // Height for laptops
    },
    borderRadius: '50%',
    padding: '2px',
    background: 'white',
    border: '2px solid transparent',
    backgroundImage: 'linear-gradient(#e0f2e9, #e0f2e9), linear-gradient(90deg, #56ab2f, #a8e063)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    color: 'white',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundImage: 'linear-gradient(white, white), linear-gradient(90deg, #a8e063, #56ab2f)',
    },
  }}
>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <p
      style={{
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '500',
        fontSize: {
          xs: '8px',  // Font size for phones
          sm: '14px',  // Font size for tablets
          md: '15px'   // Font size for laptops
        },
        lineHeight: '15px',
        marginTop: '13px',
        marginRight: '8px',
        background: 'linear-gradient(90deg, #56ab2f, #a8e063)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
    >
      Get
    </p>
    <ArrowIcon style={{ width: '18px', height: '18px' }} />
  </div>

  <p
    style={{
      fontFamily: 'Poppins, sans-serif',
      fontWeight: '500',
      fontSize: {
        xs: '8px',  // Font size for phones
        sm: '14px',  // Font size for tablets
        md: '15px'   // Font size for laptops
      },
      lineHeight: '13px',
      marginTop: '4px',
      background: 'linear-gradient(90deg, #56ab2f, #a8e063)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    }}
  >
    Started
  </p>
</Button>


        </Box>
      </motion.div>

      {/* Right Side Image with Animation */}
      <motion.div
        variants={imageVariant}
        initial="hidden"
        animate="visible"
        style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
            alignItems: 'center',
            padding: 2,
          }}
        >
          <img
            src={scrapImage}
            alt="Scrap Material"
            style={{
              maxWidth: '100%',
              height: 'auto',
              opacity: 0.9,
            }}
          />
        </Box>
      </motion.div>
    </Box>
  );
};
export default Picturebaner;