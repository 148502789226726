import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { auth } from '../firebase/config';
import { Firebase } from "../firebase/config";
const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
};

const AuthContext = ({ children }) => {
  const[name ,setname]=useState(null);
  const[display_picture ,setdisplay]=useState(null);
  const[phone ,setphone]=useState(null);
  const [favourite_products,setfavorit]=useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [modal, setModal] = useState({ isOpen: false, title: '', content: '' });
  const [alert, setAlert] = useState({
    isAlert: false,
    severity: 'info',
    message: '',
    timeout: null,
    location: '',
  });
  const [loading, setLoading] = useState(false);

  const signUp = (email, password,username) => {
    return createUserWithEmailAndPassword(auth, email, password)
    .then((result) => {
        Firebase.firestore().collection("users").doc(result.user.uid).set(
          {
          id: result.user.uid,
          name:username,
          display_picture,
          phone,
          favourite_products,
          devicetoken:'',
        }
        );
      
      }
    );
  };
  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };
  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider).then(async (result) => {
      const userDocSnapshot =  Firebase.firestore().collection("users");
      const check =await userDocSnapshot.doc(result.user.uid).get();
      if(check.exists){
        console.log("User Already Exists");
      }
      else{
        Firebase.firestore().collection("users").doc(result.user.uid).set(
          {
          id: result.user.uid,
          name:result.user.displayName,
          display_picture,
          phone,
          favourite_products,
          devicetoken:'',
        }
        );
      }
    }
  );
  };
  const logout = () => {
    return signOut(auth);
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      // console.log('user status changed: ', user);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signUp,
    login,
    logout,
    modal,
    setModal,
    loginWithGoogle,
    alert,
    setAlert,
    loading,
    setLoading,
    resetPassword,
  };
  return <authContext.Provider {...{ value }}>{children}</authContext.Provider>;
};

export default AuthContext;
