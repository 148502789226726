import React from 'react';
import Modal from 'react-modal';
import { FaExclamationCircle } from 'react-icons/fa'; // For the alert icon

Modal.setAppElement('#root'); // Replace '#root' if necessary

function AlertVerifyEmail({ closeAlert }) {
  return (
    <Modal
      isOpen={true}
      onRequestClose={closeAlert}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          padding: '30px',
          borderRadius: '12px',
          textAlign: 'center',
          maxWidth: '400px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          border: 'none',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div style={{ color: '#2E7D32', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <FaExclamationCircle size={48} style={{ marginBottom: '15px' }} />
        <h2 style={{ color: '#1B5E20', marginBottom: '10px', fontSize: '1.5em', fontWeight: 'bold' }}>
          Verify Your Email
        </h2>
        <p style={{ fontSize: '1em', color: '#4F4F4F', marginBottom: '25px' }}>
          To continue, please verify your email address. Check your inbox for a verification email and follow the link provided.
        </p>
        <button
          onClick={closeAlert}
          style={{
            padding: '10px 20px',
            fontSize: '1em',
            color: 'white',
            backgroundColor: '#2E7D32',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.3s ease',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#1B5E20')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#2E7D32')}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}

export default AlertVerifyEmail;
