import React,{useEffect,useContext} from 'react';

import Header from '../Header/Header';
import Picturebaner from '../ScrapBanners/PictureBanner';
import HowItWorks from '../howWork/work';
import HomePost from '../PostScrap/HomePagePosts';
import Footer from '../Footer/Footer';


function HomeScreen(props) {
 
  return (
    <div className="homeDivd">
      <Header />
      <Picturebaner />
      <HowItWorks />
      <HomePost />
      <Footer />
    </div>
  );
}

export default HomeScreen;
 
