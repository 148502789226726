

import React, { useState } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { Search, Chat, Handshake } from '@mui/icons-material';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const stepsData = {
  sellers: [
    {
      id: 1,
      title: 'List Your Product',
      description: 'Easily post your product with all necessary details.',
      icon: <Search sx={{ fontSize: 60, color: '#56ab2f' }} />,
    },
    {
      id: 2,
      title: 'Negotiate with Buyers',
      description: 'Chat and negotiate prices directly with potential buyers.',
      icon: <Chat sx={{ fontSize: 60, color: '#56ab2f' }} />,
    },
    {
      id: 3,
      title: 'Deal directly with customers',
      description: 'Deal directly with customers in your nation and across the globe',
      icon: <Handshake sx={{ fontSize: 60, color: '#56ab2f' }} />,
    },
  ],
  buyers: [
    {
      id: 1,
      title: 'Find What You Need',
      description: 'Browse through thousands of offers posted in the marketplace.',
      icon: <Search sx={{ fontSize: 60, color: '#56ab2f' }} />,
    },
    {
      id: 2,
      title: 'Negotiate the Best Deal',
      description: 'Negotiate with multiple sellers in real-time.',
      icon: <Chat sx={{ fontSize: 60, color: '#56ab2f' }} />,
    },
    {
      id: 3,
      title: 'Buy Directly from Suppliers',
      description: 'Buy directly from suppliers locally or internationally.',
      icon: <Handshake sx={{ fontSize: 60, color: '#56ab2f' }} />,
    },
  ],
};




const HowItWorks = () => {
  const [selectedMode, setSelectedMode] = useState('sellers');

  // Use hooks at the top level of the component
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // Trigger the animation when the component is in view
  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const handleModeChange = (mode) => {
    setSelectedMode(mode);
  };

  const renderSteps = (steps) =>
    steps.map((step) => (
      <Grid item xs={12} sm={4} key={step.id}>
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: step.id * 0.1 } },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              padding: 2,
            }}
          >
            {step.icon}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              {step.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {step.description}
            </Typography>
          </Box>
        </motion.div>
      </Grid>
    ));

  return (
    <Box
      sx={{
        padding: 10,
        // background: 'linear-gradient(135deg, #ffffff, #e0f2e9)',
        borderRadius: 0,
      }}
    >
      <Typography variant="h4" gutterBottom textAlign="center" fontWeight="bold">
        How Our Platform Works
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4,paddingTop :5 }}>
        <Button
          variant={selectedMode === 'sellers' ? 'contained' : 'outlined'}
          onClick={() => handleModeChange('sellers')}
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
            borderRadius: 20,
            mx: 1,
            backgroundColor: selectedMode === 'sellers' ? '#56ab2f' : 'transparent',
            color: selectedMode === 'sellers' ? 'white' : '#56ab2f',
            borderColor: '#56ab2f',
            '&:hover': {
              backgroundColor: selectedMode === 'sellers' ? '#56ab2f' : 'rgba(86, 171, 47, 0.1)',
            },
          }}
        >
          Sellers
        </Button>
        <Button
          variant={selectedMode === 'buyers' ? 'contained' : 'outlined'}
          onClick={() => handleModeChange('buyers')}
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
            borderRadius: 20,
            mx: 1,
            backgroundColor: selectedMode === 'buyers' ? '#56ab2f' : 'transparent',
            color: selectedMode === 'buyers' ? 'white' : '#56ab2f',
            borderColor: '#56ab2f',
            '&:hover': {
              backgroundColor: selectedMode === 'buyers' ? '#56ab2f' : 'rgba(86, 171, 47, 0.1)',
            },
          }}
        >
          Buyers
        </Button>
      </Box>

      <Grid container spacing={3}>
        {selectedMode === 'sellers'
          ? renderSteps(stepsData.sellers)
          : renderSteps(stepsData.buyers)}
      </Grid>
    </Box>
  );
};

export default HowItWorks;



// without animation
// HowItWorks.js

// import React, { useState } from 'react';
// import { Box, Button, Typography, Grid } from '@mui/material';
// import { Search, Chat, Handshake } from '@mui/icons-material'; // Importing Material-UI icons
// import { motion } from 'framer-motion';

// const stepsData = {
//   sellers: [
//     {
//       id: 1,
//       title: 'List Your Product',
//       description: 'Easily post your product with all necessary details.',
//       icon: <Search sx={{ fontSize: { xs: 40, sm: 50, md: 60 }, color: '#56ab2f' }} />, // Responsive icon size
//     },
//     {
//       id: 2,
//       title: 'Negotiate with Buyers',
//       description: 'Chat and negotiate prices directly with potential buyers.',
//       icon: <Chat sx={{ fontSize: { xs: 40, sm: 50, md: 60 }, color: '#56ab2f' }} />, // Responsive icon size
//     },
//     {
//       id: 3,
//       title: 'Deal directly with customers',
//       description: 'Deal directly with customers in your nation and across the globe.',
//       icon: <Handshake sx={{ fontSize: { xs: 40, sm: 50, md: 60 }, color: '#56ab2f' }} />, // Responsive icon size
//     },
//   ],
//   buyers: [
//     {
//       id: 1,
//       title: 'Find What You Need',
//       description: 'Browse through thousands of offers posted in the marketplace.',
//       icon: <Search sx={{ fontSize: { xs: 40, sm: 50, md: 60 }, color: '#56ab2f' }} />, // Responsive icon size
//     },
//     {
//       id: 2,
//       title: 'Negotiate the Best Deal',
//       description: 'Negotiate with multiple sellers in real-time.',
//       icon: <Chat sx={{ fontSize: { xs: 40, sm: 50, md: 60 }, color: '#56ab2f' }} />, // Responsive icon size
//     },
//     {
//       id: 3,
//       title: 'Buy Directly from Suppliers',
//       description: 'Buy directly from suppliers locally or internationally.',
//       icon: <Handshake sx={{ fontSize: { xs: 40, sm: 50, md: 60 }, color: '#56ab2f' }} />, // Responsive icon size
//     },
//   ],
// };

// const HowItWorks = () => {
//   const [selectedMode, setSelectedMode] = useState('sellers');

//   const handleModeChange = (mode) => {
//     setSelectedMode(mode);
//   };

//   const renderSteps = (steps) =>
//     steps.map((step) => (
//       <Grid item xs={12} sm={4} key={step.id}>
//         <motion.div
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.5, delay: step.id * 0.1 }}
//         >
//           <Box
//             sx={{
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//               textAlign: 'center',
//               padding: 2,
//             }}
//           >
//             {step.icon}
//             <Typography
//               variant="h6"
//               fontWeight="bold"
//               gutterBottom
//               sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }} // Responsive font size
//             >
//               {step.title}
//             </Typography>
//             <Typography
//               variant="body2"
//               color="textSecondary"
//               sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' } }} // Responsive font size
//             >
//               {step.description}
//             </Typography>
//           </Box>
//         </motion.div>
//       </Grid>
//     ));

//   return (
//     <Box
//       sx={{
//         padding: 10,
//         background: 'linear-gradient(135deg, #ffffff, #e0f2e9)', // Applying gradient background
//         borderRadius: 0, // Zero border radius as requested
//       }}
//     >
//       <Typography variant="h4" gutterBottom textAlign="center" fontWeight="bold">
//         How Our Platform Works
//       </Typography>

//       {/* Buttons for Sellers and Buyers */}
//       <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
//         <Button
//           variant={selectedMode === 'sellers' ? 'contained' : 'outlined'}
//           onClick={() => handleModeChange('sellers')}
//           sx={{
//             textTransform: 'none',
//             fontWeight: 'bold',
//             borderRadius: 20,
//             mx: 1,
//             backgroundColor: selectedMode === 'sellers' ? '#56ab2f' : 'transparent',
//             color: selectedMode === 'sellers' ? 'white' : '#56ab2f',
//             borderColor: '#56ab2f',
//             '&:hover': {
//               backgroundColor: selectedMode === 'sellers' ? '#56ab2f' : 'rgba(86, 171, 47, 0.1)',
//             },
//           }}
//         >
//           Sellers
//         </Button>
//         <Button
//           variant={selectedMode === 'buyers' ? 'contained' : 'outlined'}
//           onClick={() => handleModeChange('buyers')}
//           sx={{
//             textTransform: 'none',
//             fontWeight: 'bold',
//             borderRadius: 20,
//             mx: 1,
//             backgroundColor: selectedMode === 'buyers' ? '#56ab2f' : 'transparent',
//             color: selectedMode === 'buyers' ? 'white' : '#56ab2f',
//             borderColor: '#56ab2f',
//             '&:hover': {
//               backgroundColor: selectedMode === 'buyers' ? '#56ab2f' : 'rgba(86, 171, 47, 0.1)',
//             },
//           }}
//         >
//           Buyers
//         </Button>
//       </Box>

//       {/* Displaying Steps based on the selected mode */}
//       <Grid container spacing={3}>
//         {selectedMode === 'sellers'
//           ? renderSteps(stepsData.sellers)
//           : renderSteps(stepsData.buyers)}
//       </Grid>
//     </Box>
//   );
// };

// export default HowItWorks;
